import React, { useState, useRef } from 'react';
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby';
import Container from '../components/Container';
import Sucursal from '../components/Sucursal';
import 'mapbox-gl/dist/mapbox-gl.css';
import animateScrollTo from 'animated-scroll-to';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';

export default (props) => {
  const sucursales = props.data.allSucursalesJson.nodes;
  const scrollToRef = (ref) => animateScrollTo(ref.current);

  const [mainSucursal, setMainSucursal] = useState(sucursales[0]);



  const mapRef = useRef(null);

  const switchSucursal = (sucursal) => {
    setMainSucursal(sucursal);
    scrollToRef(mapRef);
  }

  return (
    <Layout>

      <PageHeader>
        <h2 className="text-6xl">Sucursales</h2>
        <p className="mt-2 text-sm">
          <Link to="/" className="underline">Inicio</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/sucursales" className="underline">Sucursales</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/foraneas" className="underline">Foráneas</Link>
        </p>
      </PageHeader>

      <Container hideAside={true} ref={mapRef}>
        
        <div className="flex flex-wrap">
          {
            sucursales.map((sucursal, index) => (
              <Sucursal sucursal={sucursal} key={index} view={() => switchSucursal(sucursal)} />
            ))
          }
        </div>
      </Container>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query {
    allSucursalesJson(filter: {city: {ne: "Tuxtla Gtz"}},sort:{fields:[name], order:ASC}) {
      nodes {
        name
        address
        phone
        email
        coords{
          latitude
          longitude
        }
        horario{
          weekdays{
            starts_at
            ends_at
            starts_at_2
            ends_at_2
          }
          saturday{
            starts_at
            ends_at
          }
          sunday{
            starts_at
            ends_at
          }
        }
      }
    }
    sucursalesJson(name:{eq:"Matriz"}){
    coords{
        latitude
        longitude
      }
    }
  }

`;